@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.VideoHero {
  position: relative;

  height: calc(55.9vw + 238px);

  @media screen and (max-width: $mobile-max-breakpoint) {
    height: 667px;
  }

  @media screen and (min-width: $desktop-min-breakpoint) {
    height: 810px;
  }
}

.VideoHero__VideoContainer {
  display: none;
  height: 100%;
  video {
    display: none;
  }

  @media (min-width: $tablet-min-breakpoint) {
    display: block !important;
    video {
      display: block !important;
    }
  }
}

.VideoHero__MobilePosterContainer {
  height: 100%;
  display: none;

  img {
    display: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: $tablet-min-breakpoint) {
    display: block;
    img {
      display: block !important;
    }
  }
}

.VideoHeroFooter {
  position: absolute;
  bottom: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--white);
  }

  & > div:first-child {
    margin: 0 auto;
  }

  button,
  a,
  a:hover,
  a:focus,
  a:active {
    border: none;
  }
}

.VideoHeroFooter__inner {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: center;

  padding: 0 var(--gutter);

  // margin-bottom: var(--spacing-l);
  margin-bottom: rem-calc(38);

  & > div {
    display: flex;
    flex: 33.33%;

    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(3) {
      justify-content: flex-end;
    }
  }
}

.video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.DefaultControls {
  display: flex;
}

.DefaultControls__button {
  display: none;
  color: var(--dark-grey);
  font-size: rem-calc(24);
  line-height: rem-calc(30);

  & :hover {
    color: var(--white);
  }

  & :active {
    color: var(--white);
  }

  &--active {
    color: var(--white);
    font-feature-settings: 'ss07' on;
  }

  @media (min-width: $tablet-min-breakpoint) {
    display: block;
  }
}
